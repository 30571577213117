<template>
  <div>
    <SubHeader>
      <b-row class="mt-2 mb-1">
        <b-col cols="8" class="mr-auto">
          <b-row>
            <b-col cols="auto">
              <b-icon-arrow-clockwise v-if="!reloading" :variant="iconVariant" @click="refresh(true)" font-scale="1.5"
                                      class="clickable computer-header-icon"/>
              <b-icon-arrow-clockwise v-else :variant="iconVariant" font-scale="1.5" animation="spin"
                                      class="computer-header-icon"/>
              <small class="ml-2">Letzte Aktualisierung: {{ lastDataFetched }}</small>
            </b-col>
            <b-col cols="4">
              <b-form-input v-model="search" debounce="500" size="sm"
                            placeholder="Suche nach Vorname, Nachname oder Kundennummer..."/>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto" class="ml-auto mt-1">
          <span>Anzahl Standorte: {{ customerGroupsCount }}</span>
          <span class="ml-2">|</span>
          <span class="ml-2">Anzahl Kund:innen: {{ customersCount }}</span>
        </b-col>
      </b-row>
    </SubHeader>
    <div v-if="groupsFiltered">
      <div v-for="group in groupsFiltered" :key="group.id">
        <Group :group="group" class="mt-3">
          <template #group-content>
            <div v-for="customer in customers(group)" :key="customer.id">
              <CustomerRow :customer="customer" @refresh="refresh"/>
            </div>
          </template>
        </Group>
      </div>
    </div>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {mapActions, mapGetters} from "vuex";
import Group from "@/components/lists/Group";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";
import CustomerRow from "@/components/lists/customerList/CustomerRow";

export default {
  name: "CustomerList",
  components: {
    Group,
    SubHeader,
    ScrollToTop,
    CustomerRow
  },
  data() {
    return {
      search: '',
      interval: null,
      reloading: false,
      lastDataFetched: '-'
    }
  },
  methods: {
    ...mapActions(['resetCustomers']),
    ...mapActions(['getCustomerList']),
    ...mapActions(['setLoadingState']),
    customers(group) {
      return _.filter(group.customers, (customer) => {
        return customer.kdnr.toLowerCase().includes(this.search.toLowerCase()) ||
               customer.zuname.toLowerCase().includes(this.search.toLowerCase()) ||
               customer.vorname.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    async getCustomers() {
      this.setLoadingState(true)
      await this.refresh(false)
      this.setLoadingState(false)
    },
    async refresh(forceReload = true) {
      this.reloading = true
      await this.getCustomerList(forceReload)
      this.lastDataFetched = dayjs().format('HH:mm:ss')
      this.reloading = false
    }
  },
  computed: {
    ...mapGetters(['customerGroups']),
    ...mapGetters(['customerGroupsCount']),
    ...mapGetters(['customersCount']),
    ...mapGetters(['userThemeLight']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'light'
    },
    groupsFiltered() {
      return _.filter(this.customerGroups, (group) => {
        return _.filter(group.customers, (customer) => {
          return customer.kdnr.toLowerCase().includes(this.search.toLowerCase()) ||
                 customer.zuname.toLowerCase().includes(this.search.toLowerCase()) ||
                 customer.vorname.toLowerCase().includes(this.search.toLowerCase())
        }).length > 0
      })
    }
  },
  beforeMount() {
    this.resetCustomers()
  },
  mounted() {
    this.getCustomers();
    this.interval = setInterval(function () {
      this.refresh(false)
    }.bind(this), 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.resetCustomers()
  }
}
</script>

<style lang="scss" scoped>
</style>
