<template>
  <b-card body-class="p-0 mb-2" header-tag="header" :header-class="headerClass">
    <template #header>
      <b-container fluid class="px-2 clickable">
        <b-row @click.self="getDetails">
          <b-col cols="auto" class="p-0" @click.self="getDetails">
            <CustomerOnline :customer="customer"/>
            <CustomerType :customer="customer"/>
          </b-col>
          <b-col cols="1" @click.self="getDetails">
            <Clipboard :value="customer.kdnr" title="Kundennummer kopieren"/>
            <span v-if="customer.kdnr">
              <a target="_blank" :href="'https://webams.alfanetz.de/teilnehmer/Teilnehmer/' + kdnr">
                <b-icon-link45deg :id="customer.kdnr"/>
                <b-tooltip :target="customer.kdnr">Link zum AMS</b-tooltip>
              </a>
            </span>
          </b-col>
          <b-col cols="2" @click.self="getDetails">
            <Clipboard :value="customer.zuname + ', ' + customer.vorname" title="Name kopieren"/>
          </b-col>
          <b-col cols="3" @click.self="getDetails">
            <Clipboard :value="customer.intklr + ' / ' + customer.kurs" title="Klassenraumnummer und Kurs kopieren"/>
          </b-col>
          <b-col cols="auto" class="ml-auto p-0" @click.self="getDetails">
            <EmployeeDisplay :customer="customer"/>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <TransitionExpand>
      <CustomerDetails v-if="expand" :customer="customer" @refresh="refresh"/>
    </TransitionExpand>
  </b-card>
</template>

<script>
import Clipboard from "@/components/utility/Clipboard.vue";
import TransitionExpand from "@/components/utility/TransitionExpand";
import CustomerDetails from "@/components/details/customerDetails/CustomerDetails";
import CustomerType from "@/components/lists/customerList/customerRow/CustomerType";
import CustomerOnline from "@/components/lists/customerList/customerRow/CustomerOnline";
import EmployeeDisplay from "@/components/lists/customerList/customerRow/EmployeeDisplay";

export default {
  name: "CustomerRow",
  props: ['customer'],
  components: {
    CustomerType,
    CustomerOnline,
    CustomerDetails,
    Clipboard,
    EmployeeDisplay,
    TransitionExpand
  },
  data() {
    return {
      expand: false
    }
  },
  methods: {
    async getDetails() {
      if(!this.expand) {
        this.expand = true
      } else {
        clearInterval(this.interval)
        this.expand = false
        this.details = null
      }
    },
    refresh() {
      this.$emit('refresh')
    }
  },
  computed: {
    headerClass() {
      return ['card-row', {rounded: !this.expand}]
    },
    kdnr() {
      let reg = /(\d*)[A-Z]{2}/
      if(this.customer.kdnr[0] === 'D' || this.customer.kdnr[0] === 'T') {
        return null
      }
      return this.customer.kdnr.match(reg)[1]
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/details/Details.scss';
</style>
